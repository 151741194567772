import React, { createContext, useContext, useState, useEffect} from 'react';

const VariablesGlobales = createContext();

export const useVariablesGlobales = () => useContext(VariablesGlobales);

export const GlobalProvider = ({ children }) => {
  const [cuentaPL, setCuentaPL] = useState('3');
  const [authTokenExist, setAuthTokenExist] = useState(false);
  const token = localStorage.getItem('authLaravel');
  const [authToken, setAuthToken] = useState(token);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== '') {
      
      setAuthTokenExist(true);
    }
  }, []);
  //console.log('desde globales:'+authTokenExist);

  // Objeto que contiene todas las variables de estado
  const globalState = {
    cuentaPL,
    setCuentaPL,
    authTokenExist,
    setAuthTokenExist,
    authToken,
    setAuthToken
  };

  return (
    <VariablesGlobales.Provider value={globalState}>
      {children}
    </VariablesGlobales.Provider>
  );
};