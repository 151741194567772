import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { SeccionUno, SeccionDos, MultiSteps1 } from "../components/sections";
import { Button, Modal } from 'react-bootstrap';
import {   BrowserRouter as Router,
    useHistory, useParams } from 'react-router-dom';
  import { useVariablesGlobales } from '../globales/VariablesGlobales';
  import axios from 'axios';

import {Activos, Participantes} from '../components/DoughnutCharts';
import PolarAreaChart from '../components/PolarAreaChart';
import LinesChart from '../components/LineCharts';
import { UsuariosParticipantes, UsuariosActivos, PanelLider } from '../components/EChartsBarChart';

axios.defaults.withCredentials = false;
axios.defaults.withXSRFToken = true;


export function Resumen() {

    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    // Cargo variables del servidor
    const [datosLider, setDatosLider] = useState(null);
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [datosDistribuidor, setDatosDistribuidor] = useState(null);
    const [listadoUsuarios, setListadoUsuarios] = useState(null);
    const [usuariosTotales, setUsuariosTotales] = useState(null);
    const [usuariosActivos, setUsuariosActivos] = useState(null);
    const [usuariosParticipantes, setUsuariosParticipantes] = useState(null);
    const [usuariosInactivos, setUsuariosInactivos] = useState(null);
    const [totalSesiones, setTotalSesiones] = useState(null);
    const [totalTrivias, setTotalTrivias] = useState(null);
    const [totalJackpots, setTotalJackpots] = useState(null);
    const [pendientesSesiones, setPendientesSesiones] = useState(null);
    const [pendientesTrivias, setPendientesTrivias] = useState(null);
    const [pendientesJackpots, setPendientesJackpots] = useState(null);
    const [porcentajeActivos, setPorcentajeActivos] = useState(0);

    const [arrayNombres, setArrayNombres] = useState(null);
    const [arrayNombresSesiones, setArrayNombresSesiones] = useState(null);
    const [arrayNombresTrivias, setArrayNombresTrivias] = useState(null);
    const [topSesiones, setTopSesiones] = useState(null);
    const [topTrivias, setTopTrivias] = useState(null);
    const [top10, setTop10] = useState(null);

    const porcentajeConDosDecimales = porcentajeActivos.toFixed(2);


    const[arrayFechas, setArrayFechas] = useState(null);
    const[arrayVisualizaciones, setArrayVisualizaciones] = useState(null);
    const[arrayEvaluaciones, setArrayEvaluaciones] = useState(null);
    const[arrayTrivias, setArrayTrivias] = useState(null);
    const[arrayJackpots, setArrayJackpots] = useState(null);

      // Termina el Auth redirect
    useEffect(() => {
    // llamada para los datos de la temporada
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/datos_basicos_lider', {
        params: {
            id_cuenta: cuentaPL,
            id_usuario: id_local
        }
        })
        .then(function (response) {
        //console.log(response);
        setDatosLider(response.data.usuario);
        setDatosSuscripcion(response.data.suscripcion);
        setDatosTemporada(response.data.temporada);
        setDatosDistribuidor(response.data.distribuidor);
        setListadoUsuarios(response.data.suscriptores);
        setUsuariosTotales(response.data.totales);
        setUsuariosActivos(response.data.activos);
        setUsuariosParticipantes(response.data.participantes);
        let usuarios_inactivos = parseInt(response.data.totales) - parseInt(response.data.activos);
        setUsuariosInactivos(usuarios_inactivos);
        
        setTotalSesiones(response.data.sesiones);
        setTotalTrivias(response.data.trivias);
        setTotalJackpots(response.data.jackpots);
        setPendientesSesiones(response.data.sesiones_pendientes);
        setPendientesTrivias(response.data.trivias_pendientes);
        setPendientesJackpots(response.data.jackpots_pendientes);
        let porcentaje_activos = (parseInt(response.data.activos)*100)/parseInt(response.data.totales);
        setPorcentajeActivos(porcentaje_activos);
        setTopSesiones(response.data.top_sesiones);
        setTopTrivias(response.data.top_trivias);
        setTop10(response.data.top_10);
        setArrayNombres(response.data.array_nombres);
        setArrayNombresSesiones(response.data.array_nombres_sesiones);
        setArrayNombresTrivias(response.data.array_nombres_trivias);

        setArrayFechas(response.data.array_fechas);
        setArrayVisualizaciones(response.data.engagement_visualizaciones);
        setArrayEvaluaciones(response.data.engagement_evaluaciones);
        setArrayTrivias(response.data.engagement_trivias);
        setArrayJackpots(response.data.engagement_jackpots);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }, []);
    


    if(datosLider){
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--50 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-left">
                                    <h3 className="title">Panel Líder <span className="color-third">{datosDistribuidor.nombre}</span> </h3> <br/>
                                </div>
                                <div className="rbt-author-meta mb--10">
                                    <div className="rbt-avater">
                                        <a href="#"><img src="/assets/images/client/avatar-02.png" alt="Sophia Jaymes" /></a>
                                    </div>
                                        <div className="rbt-author-info text-white">
                                            {datosLider.nombre} {datosLider.apellidos}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                
        
              <div className="rbt-counterup-area rbt-section-overlayping-top rbt-section-gapBottom pt--150 pb--0">
                
                <div className="section-title text-center mb--40 mt--60">
                    <p className="description has-medium-font-size mt--20 mb--0">There are many variations of passages of the Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
                    <div className="rbt-button-group mt--30">
                        <a className="rbt-btn btn-gradient hover-icon-reverse" href="/lider/usuarios">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Agregar usuario</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                        <a className="rbt-btn hover-icon-reverse btn-border" href="/usuario/perfil">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Salir</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                    </div>
                </div>
               
                <div className="container">
                    <div className="row g-5">
                        <div className="section-title text-center">
                            <h2 className="title">Desempeño de usuarios</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="rbt-card variation-01 rbt-hover">
                            <h5 className="rbt-card-title center">Registrados</h5>
                            <hr/>
                                <div className="rbt-card-body">
                                    <h3 className="rbt-card-text" style={{textAlign: 'center'}}>{usuariosTotales} <br/> Totales</h3>
                                </div>
                            </div>
                        </div>
                        {/* Start Single Event  */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="rbt-card variation-01 rbt-hover">
                            <h5 className="rbt-card-title center">Usuarios activos</h5>
                            <hr/>
                                <div className="rbt-card-img">
                                    <UsuariosActivos activos={usuariosActivos} no_activos={usuariosInactivos} />
                                    
                                </div>
                                <div className="rbt-card-body">
                                    <p className="rbt-card-text">Los usuarios activos entraron al sitio un total de {usuariosActivos} veces (total de logins)</p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Event  */}
                        {/* Start Single Event  */}
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="rbt-card variation-01 rbt-hover">
                            <h5 className="rbt-card-title center">Usuarios participantes</h5>
                            <hr/>
                                <div className="rbt-card-img">
                                <UsuariosParticipantes porcentaje={porcentajeActivos}/>
                                </div>
                                <div className="rbt-card-body">
                                    <p className="rbt-card-text">{porcentajeConDosDecimales}% de los {usuariosTotales} Usuarios Registrados, participaron en al menos una actividad</p>
                                </div>
                            </div>
                        </div>
                        {/* End Single Event  */}
                    </div>
                </div>
              </div>
        
                <div className="rbt-service-area bg-color-white rbt-section-gap pt--30 pb--0">
                    <div className="container">
                        {/*  Start Card Area */}
                        <div className="row row--15 mt_dec--30">
        
                            {/*  Start Service Grid  */}
                            <div className="col-lg-12 col-xl-12 col-xxl-12 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="row">
                                            <div className="col">
                                            <form className="row justify-content-end" action="https://pl-electrico.panduitlatam.com/usuarios/vista_lider"  method="get" encType="multipart/form-data">
                                                <input type="hidden" name="id_distribuidor" value="" />
                                            <div className="col">
                                                <div className="form-group d-flex">
                                                    <input type="date" name="fecha_desde" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group d-flex">
                                                    <input type="date" name="fecha_hasta" className="form-control"/>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <a className="rbt-btn hover-icon-reverse btn-sm" href="#">Filtrar</a>
                                            </div>
                                            <div className="col-2">
                                                {/* Button trigger modal */}
                                                        <a className="rbt-btn btn-sm" style={{background:'#f0b323'}} href="#" >Descargar reportes</a>
                                                    
        
                                                    {/* Modal */}
                                                    <div className="modal fade" id="reportesModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg" style={{margintop: '150px'}}>
                                                        <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="exampleModalLabel">Reportes</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <h3 className="text-center">Seleccione una opción para descargar una hoja de cálculo con la información</h3>
                                                        <table className="table">
                                                                <tbody><tr>
                                                                    <td><h5>Usuarios</h5><p>Este es el compilado donde podrás ver  la cantidad de usuarios activos en el programa</p></td>
                                                                    {/*<td><h5>Engagement</h5><p>Este es el compilado donde podrás ver la cantidad de personas que completaron sesiones o trivias.</p></td>*/}
                                                                    <td><h5>Puntos</h5><p>Este es el compilado donde podrás ver la participación de los usuarios de tu compañía,  puntaje.</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><a href="https://pl-electrico.panduitlatam.com/usuarios/descargar_usuarios_lider" className="btn btn-primary" style={{backgroundcolor: '#064A76'}}>Descargar</a></td>
                                                                    <td><a href="https://pl-electrico.panduitlatam.com/usuarios/descargar_general_lider?id_distribuidor=Rocky Creativo" className="btn btn-info" style={{backgroundcolor: '#064A76'}}>Descargar</a></td>
                                                                </tr>
                                                            </tbody></table>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                            </div>
                                        </form>
                                            </div>
                                        </div>
                                        <div className="alert alert-warning text-center" ><p style={{fontSize:'12px', marginBottom:'5px'}}><b>Algunas fechas de lanzamiento podrían no aparecer en las acotaciones de la gráfica. </b>
                                         Para visualizar las fechas de los lanzamientos con mayor detalle, seleccione un periodo no mayor a 30 días.</p>
                                        </div>
                                        
                                        <PanelLider 
                                            fechas={arrayFechas}
                                            visualizaciones={arrayVisualizaciones}
                                            evaluaciones={arrayEvaluaciones}
                                            trivias={arrayTrivias}
                                            jackpots={arrayJackpots} />
                                        
        
                                        <div className="rbt-service-area bg-color-white rbt-section-gap">
                                            <div className="container">
                                                <div className="row mb--60">
                                                    <div className="col-lg-12">
                                                        <div className="section-title text-center">
                                                            <h2 className="title">Actividades Completadas</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Start Card Area */}
                                                <div className="row row--15 mt_dec--30">
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-flipbox">
        
                                                            <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-1">
                                                                <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                                                    <div className="icon">
                                                                        <img src="/assets/images/icons/card-icon-1.png" alt="card-icon" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h5 className="title"><a href="#">Sesiones</a></h5>
                                                                        <p>Las sesiones de video se pueden ver en cualquier momento después de su estreno</p>
                                                                        
                                                                        
                                                                    </div>
                                                                </div>
        
                                                                <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                                                    <h4 style={{fontWeight: '700'}} className="counter">Programadas {totalSesiones}</h4>
                                                                    <h4 style={{fontWeight: '700'}} className="counter">Proximas {pendientesSesiones}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-flipbox">
                                                            <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-2">
                                                                <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                                                    <div className="icon">
                                                                        <img src="/assets/images/icons/card-icon-2.png" alt="card-icon" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h5 className="title"><a href="#">Trivias</a></h5>
                                                                        <p>Trivias</p>
                                                                        <p>Actividades temporales que solo se pueden accesar un tiempo determinado</p>
                                                                    </div>
                                                                </div>
        
                                                                <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                                                    <h4 style={{fontWeight: '700'}} className="counter">Totales <small>{totalTrivias}</small></h4>
                                                                    <h4 style={{fontWeight: '700'}} className="counter">Próximas <small>{pendientesTrivias}</small></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-flipbox">
                                                            <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-3">
                                                                <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                                                    <div className="icon">
                                                                        <img src="/assets/images/icons/card-icon-3.png" alt="card-icon" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h5 className="title"><a href="#">Jackpots</a></h5>
                                                                        <p>Actividades temporales que con algo de suerte aumentaran tu puntaje</p>
                                                                    </div>
                                                                </div>
        
                                                                <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                                                    <h4 style={{fontWeight: '700'}} className="counter">Totales {totalJackpots}</h4>
                                                                    <h4 style={{fontWeight: '700'}} className="counter">Próximas {pendientesJackpots}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-flipbox">
                                                            <div className="rbt-flipbox-wrap rbt-service rbt-service-1 card-bg-4">
                                                                <div className="rbt-flipbox-front rbt-flipbox-face inner">
                                                                    <div className="icon">
                                                                        <img src="/assets/images/icons/card-icon-4.png" alt="card-icon" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h5 className="title"><a href="#">Engagement</a></h5>
                                                                        <p>{porcentajeActivos}% de engagement</p>
                                                                        {/* <a className="rbt-btn-link stretched-link" href="#">Learn More<i className="feather-arrow-right"></i></a> */}
                                                                    </div>
                                                                </div>
        
                                                                <div className="rbt-flipbox-back rbt-flipbox-face inner">
                                                                    <div className="icon">
                                                                        <img src="/assets/images/icons/card-icon-4.png" alt="card-icon" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <h5 className="title"><a href="#">Engagement</a></h5>
                                                                        
                                                                        <p>Live className dolor sit, amet consectetur adipisicing elit. Minima error reiciendis.</p>
                                                                        
                                                                            
                                                                                <div className="content" >
                                                                                    <h2 style={{fontWeight: '700'}} className="counter"><span className="odometer odometer-auto-theme" data-count="500"><div className="odometer-inside"><span className="odometer-digit"><span className="odometer-digit-spacer">8</span><span className="odometer-digit-inner"><span className="odometer-ribbon"><span className="odometer-ribbon-inner"><span className="odometer-value">5</span></span></span></span></span><span className="odometer-digit"><span className="odometer-digit-spacer">8</span><span className="odometer-digit-inner"><span className="odometer-ribbon"><span className="odometer-ribbon-inner"><span className="odometer-value">0</span></span></span></span></span><span className="odometer-digit"><span className="odometer-digit-spacer">8</span><span className="odometer-digit-inner"><span className="odometer-ribbon"><span className="odometer-ribbon-inner"><span className="odometer-value">0</span></span></span></span></span></div></span>
                                                                                    </h2>
                                                                                    <span className="subtitle">Learners &amp; counting</span>
                                                                                </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                </div>
                                                {/* End Card Area */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
        
                        </div>
                        {/*  End Card Area */}
                    </div>
                </div>
        
                <div className="rbt-advance-tab-area rbt-section-gap bg-color-extra2">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="title">Top 10</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="advance-tab-button">
                                    <ul className="nav nav-tabs tab-button-style-2" id="myTab-4" role="tablist">
                                        <li role="presentation">
                                            <a href="#" className="tab-button active" id="home-tab-4" data-bs-toggle="tab" data-bs-target="#home-4" role="tab" aria-controls="home" aria-selected="true">
                                                <span className="title">Top sesiones</span>
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a href="#" className="tab-button" id="profile-tab-4" data-bs-toggle="tab" data-bs-target="#profile-4" role="tab" aria-controls="profile-4" aria-selected="false">
                                                <span className="title">Top trivias</span>
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a href="#" className="tab-button" id="contact-tab-4" data-bs-toggle="tab" data-bs-target="#contact-4" role="tab" aria-controls="contact-4" aria-selected="false">
                                                <span className="title">Top 10 y Puntaje</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-10 offset-lg-1">
                                <div className="tab-content advance-tab-content-style-2">
                                    <div className="tab-pane fade active show" id="home-4" role="tabpanel" aria-labelledby="home-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <h5 className="rbt-card-title center">Top sesiones</h5>
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>SESION</th>
                                                                        <th>INTERACCIONES</th>
                                                                    </tr>
                                                                </thead>
                                                                
                                                                <tbody>
                                                                    {topSesiones.map((sesion, index) => (
                                                                        
                                                                        <tr  key={sesion.id}>
                                                                            <th>{index+1}</th>
                                                                            <td>{arrayNombresSesiones[sesion.id]}</td>
                                                                            <td>{sesion.puntos}</td>
                                                                        </tr>
                                                                    
                                                                    ))}
                                                                </tbody>
                                                                
                                                                
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <h5 className="rbt-card-title center">Top trivias</h5>
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>TRIVIA</th>
                                                                        <th>PARTICIPANTES</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {topTrivias.map((trivia, index) => (
                                                                        
                                                                        <tr  key={trivia.id}>
                                                                            <th>{index+1}</th>
                                                                            <td>{arrayNombresTrivias[trivia.id]}</td>
                                                                            <td>{trivia.puntos}</td>
                                                                        </tr>
                                                                    
                                                                    ))}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact-4" role="tabpanel" aria-labelledby="contact-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <h5 className="rbt-card-title center">Top 10 y Puntaje</h5>
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>NOMBRE</th>
                                                                        <th>COMPAÑIA</th>
                                                                        <th>PUNTOS</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {top10.map((usuario, index) => (
                                                                        
                                                                        <tr  key={usuario.id}>
                                                                            <th>{index+1}</th>
                                                                            <td>{arrayNombres[usuario.id]}</td>
                                                                            <td>{datosDistribuidor.nombre}</td>
                                                                            <td>{usuario.puntos}</td>
                                                                        </tr>
                                                                    
                                                                    ))}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
  }
export function Top10Lider() {
    return (
      <div>
        <h2>Top 10</h2>
      </div>
    );
  }
export function UsuariosLider() {
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    // Cargo variables del servidor
    const [recargarDatos, setRecargarDatos] = useState(false);
    const [datosLider, setDatosLider] = useState(null);
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [datosDistribuidor, setDatosDistribuidor] = useState(null);
    const [listadoUsuarios, setListadoUsuarios] = useState(null);
    const [usuariosTotales, setUsuariosTotales] = useState(null);
    const [usuariosActivos, setUsuariosActivos] = useState(null);
    const [usuariosParticipantes, setUsuariosParticipantes] = useState(null);
    const [usuariosInactivos, setUsuariosInactivos] = useState(null);
    const [totalSesiones, setTotalSesiones] = useState(null);
    const [totalTrivias, setTotalTrivias] = useState(null);
    const [totalJackpots, setTotalJackpots] = useState(null);
    const [pendientesSesiones, setPendientesSesiones] = useState(null);
    const [pendientesTrivias, setPendientesTrivias] = useState(null);
    const [pendientesJackpots, setPendientesJackpots] = useState(null);
    const [porcentajeActivos, setPorcentajeActivos] = useState(0);

    const [arrayNombre, setArrayNombres] = useState(null);
    const [topSesiones, setTopSesiones] = useState(null);
    const [topTrivias, setTopTrivias] = useState(null);
    const [top10, setTop10] = useState(null);
    const [agregandoUsuario, setAgregandoUsuario] = useState(false);

    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        categoria: ''
      });

    const [usuarioActual, setUsuarioActual] = useState({});

      
      // Termina el Auth redirect
    useEffect(() => {
    // llamada para los datos de la temporada
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/datos_basicos_lider', {
        params: {
            id_cuenta: cuentaPL,
            id_usuario: id_local
        }
        })
        .then(function (response) {
        //console.log(response);
        setDatosLider(response.data.usuario);
        setDatosSuscripcion(response.data.suscripcion);
        setDatosTemporada(response.data.temporada);
        setDatosDistribuidor(response.data.distribuidor);
        setListadoUsuarios(response.data.suscriptores);
        setUsuariosTotales(response.data.totales);
        setUsuariosActivos(response.data.activos);
        setUsuariosParticipantes(response.data.participantes);
        let usuarios_inactivos = parseInt(response.data.totales) - parseInt(response.data.activos);
        setUsuariosInactivos(usuarios_inactivos);
        
        setTotalSesiones(response.data.sesiones);
        setTotalTrivias(response.data.trivias);
        setTotalJackpots(response.data.jackpots);
        setPendientesSesiones(response.data.sesiones_pendientes);
        setPendientesTrivias(response.data.trivias_pendientes);
        setPendientesJackpots(response.data.jackpots_pendientes);
        let porcentaje_activos = (parseInt(response.data.activos)*100)/parseInt(response.data.totales);
        setPorcentajeActivos(porcentaje_activos);
        setTopSesiones(response.data.top_sesiones);
        setTopTrivias(response.data.top_trivias);
        setTop10(response.data.top_10);
        setArrayNombres(response.data.array_nombres);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }, [recargarDatos]);

    const handleChangeAgregar = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

      const handleSubmitAgregar = (e) => {
        e.preventDefault();
        // Aquí puedes llamar a una función para manejar los datos, por ejemplo:
        handleAgregarUsuario(formData);
      };

      const handleAgregarUsuario = async (datos) => {

        // VOY A EVITAR QUE PUEDAN AGREGAR AL USUARIO
        if(datos.correo!='lcvelazquez@hermos.com.mx'){
            //console.log(datos);
            setAgregandoUsuario(true);
            try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/agregar_usuario', {
                id_cuenta: cuentaPL,
                id_temporada: datosTemporada.id,
                id_distribuidor: datosDistribuidor.id,
                nombre: datos.nombre,
                apellidos: datos.apellido,
                correo: datos.correo,
            });
            //console.log(datos);
            setAgregandoUsuario(false);
            setFormData({
                nombre: '',
                apellido: '',
                correo: '',
                categoria: ''
            });
            setRecargarDatos(!recargarDatos);
            Swal.fire({
                title: '¡Proceso exitoso!',
                text: 'El usuario se ha sumado a su base. Puede visualizarlo ya en la base de su lista de usuarios.',
                icon: 'success',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
                });
            
            } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            }
        }else{
            Swal.fire({
                title: '¡Advertencia!',
                text: 'El usuario se había eliminado con anterioridad',
                icon: 'warning',
                confirmButtonColor: '#FF0000',
                confirmButtonText: 'Aceptar'
                });
        }

        
      };


        const handleRestaurarContraseña = () => {
            Swal.fire({
            title: '¿Está seguro que quiere restaurar la contraseña de este usuario?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#213746',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
            }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                title: '¡Contraseña restaurada!',
                icon: 'success',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
                });
            }
            });
        };

        const handleEliminarUsuario = () => {
            Swal.fire({
            title: '¿Está seguro que quiere eliminar a este usuario?',
            text: 'Esta opción no se puede deshacer.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#213746',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
            }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                title: '¡Usuario eliminado!',
                icon: 'success',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
                });
            }
            });
        };

        const [showModal, setShowModal] = useState(false);

        const handleOpenModal = (usuario) => {
            setShowModal(true);
            setUsuarioActual(usuario);
          };

        const handleCloseModal = () => {
            setShowModal(false);
            setUsuarioActual({});
          };

        const handleActualizarPerfil = () => {
        // Aquí puedes llamar a tu función handleSubmitActualizar
        // y pasarle los datos del usuario almacenados en usuarioActual
        handleSubmitActualizar(usuarioActual);
        handleCloseModal();
        };

        const handleChangeInput = (e) => {
            const { id, value } = e.target;
            setUsuarioActual((prevUsuario) => ({
              ...prevUsuario,
              [id]: value,
            }));
          };

          async function handleSubmitActualizar(usuarioActual) {
            // Aquí puedes realizar cualquier lógica necesaria para enviar los datos actualizados del usuario al servidor
            console.log('Datos actualizados del usuario:', usuarioActual);

            try {
                const response = await axios.post('https://plsystem.quarkservers2.com/api/actualizar_usuario', {
                  id_cuenta: cuentaPL,
                  id_temporada: datosTemporada.id,
                  id_distribuidor: datosDistribuidor.id,
                  nombre: usuarioActual.nombre,
                  apellidos: usuarioActual.apellidos,
                  correo: usuarioActual.email,
                  suscripcion: usuarioActual.suscripcion,
                });
                setRecargarDatos(!recargarDatos);
                
              } catch (error) {
                console.error('Error al enviar la solicitud:', error);
              }
          }

    if(listadoUsuarios){
        return (
            <div>
                <div className="rbt-page-banner-wrapper pb--180">
                    {/*  Start Banner BG Image  */}
                    <div className="rbt-banner-image"></div>
                    {/*  End Banner BG Image  */}
                    <div className="rbt-banner-content">
                        {/*  Start Banner Content Top  */}
                        <div className="rbt-banner-content-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
    
                                        <div className=" title-wrapper">
                                            <h1 className="title mb--0 text-white">Panel de Líder - usuarios</h1>
                                        </div>
                                        <div className="rbt-author-meta mb--10">
                                            <div className="rbt-avater">
                                                <a href="#"><img src="/assets/images/client/avatar-02.png" alt="Sophia Jaymes" /></a>
                                            </div>
                                                <div className="rbt-author-info text-white">
                                                    By Angela In Development
                                                </div>
                                        </div>
                                        <p className="description text-white">Gestiona Usuarios y Supervisa Equipos con Eficiencia. </p>
                                        <a className="rbt-btn btn-sm" href="/lider">Panel Líder</a>
                                        <a className="btn-sm ml--20 background-color-third" href="/usuario/perfil">Salir<span className="btn-icon"><i className="feather-arrow-right"></i></span></a>
                                        
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="breadcrumb-inner text-center">
                                                    <h2 className="title text-white">Detalle de usuarios</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  End Banner Content Top  */}
                    </div>
                </div>
    
                <div className="rbt-counterup-area rbt-section-overlayping-top rbt-section-gapBottom">
                    <div className="container">
                        <div className="row row--30 gy-5">
    
                            {/* Tabla de usuarios  */}
                            <div className="col-lg-8 col-xl-9 order-1 order-lg-1">
                                <div className="row g-5">
                                    {/* Start Single Event  */}
                                    <div className="col-xl-12 col-lg-6 col-md-6 col-12">
                                        <div className="rbt-card event-grid-card variation-01 rbt-hover">
                                            <div className="rbt-card-body">
                                                <div className="table-responsive mobile-table-750">
                                                    <table className="rbt-table table table-borderless">
                                                        <thead>
                                                            <tr>
                                                                <th>EDICIÓN</th>
                                                                <th>NOMBRE</th>
                                                                <th>CORREO</th>
                                                                <th>CONTRASEÑA</th>
                                                                <th>EDITAR</th>
                                                                <th>ELIMINAR</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listadoUsuarios &&
                                                            Object.values(listadoUsuarios).map((usuario, index) => (
                                                            <tr key={index}>
                                                                <th>{index + 1}</th>
                                                                <td>{usuario.nombre} {usuario.apellidos}</td>
                                                                <td>{usuario.email}</td>
                                                                <td>
                                                                <a className="rbt-btn btn-xs bg-color-warning-opacity radius-round color-white" href="#" title="Restaurar" onClick={handleRestaurarContraseña}>
                                                                    <i className="feather-rotate-ccw"></i> Restaurar
                                                                </a>
                                                                </td>
                                                                <td>
                                                                <Button className="rbt-btn btn-xs bg-color-info-opacity radius-round color-info" onClick={() => handleOpenModal(usuario)}>
                                                                    <i className="feather-edit pl--0"></i> Editar
                                                                </Button>
                                                                <Modal className="popup-notificaciones d-flex align-items-center justify-content-center" backdrop="static" centered show={showModal} onHide={handleCloseModal}>
                                                                    <Modal.Body>
                                                                    <div className="section-title">
                                                                        <h4 className="rbt-title-style-3">Editar Perfil</h4>
                                                                    </div>
                                                                    <div className="rainbow-tab-content tab-content">
                                                                        <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                                        <form action="#" className="rbt-profile-row rbt-default-form row row--15">
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="rbt-form-group">
                                                                                <label htmlFor="firstname">Nombre (s)</label>
                                                                                <input id="nombre" type="text" value={usuarioActual.nombre} onChange={handleChangeInput} />
                                                                            </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="rbt-form-group">
                                                                                <label htmlFor="lastname">Apellidos (s)</label>
                                                                                <input id="apellidos" type="text" value={usuarioActual.apellidos} onChange={handleChangeInput} />
                                                                            </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="rbt-form-group">
                                                                                <label htmlFor="username">Correo</label>
                                                                                <input id="email" type="email" value={usuarioActual.email} onChange={handleChangeInput} />
                                                                            </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="form-group">
                                                                                <label htmlFor="lastname">Categoria</label>
                                                                                <select required>
                                                                                <option value="" disabled>Seleccione una opción</option>
                                                                                <option>Especialista</option>
                                                                                <option>Vendedor</option>
                                                                                </select>
                                                                            </div>
                                                                            </div>
                                                                        </form>
                                                                        </div>
                                                                    </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer className="justify-content-between">
                                                                    <Button variant="secondary" className="rbt-btn btn-sm" onClick={handleActualizarPerfil}>
                                                                        Actualizar
                                                                    </Button>
                                                                    <Button variant="secondary" className="rbt-btn btn-sm" onClick={handleCloseModal}>
                                                                        Cerrar
                                                                    </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                </td>
                                                                <td>
                                                                <a className="rbt-btn btn-xs bg-color-danger-opacity radius-round color-danger" href="#" title="Delete" onClick={handleEliminarUsuario}>
                                                                    <i className="feather-trash-2 pl--0"></i> Eliminar
                                                                </a>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                            
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Event  */}
                                </div>
                            </div>
    
                            {/* Agregar un usuario  */}
                            <div className="col-lg-4 col-xl-3 order-2 order-lg-2">
                                <aside className="rbt-sidebar-widget-wrapper">
                                    <div className="rbt-single-widget rbt-widget-categories ">
                                        <div className="inner">
                                            <h4 className="rbt-widget-title">Agregar usuario</h4>
                                            <form id="contact-form" onSubmit={handleSubmitAgregar} className="rainbow-dynamic-form max-width-auto">
                                                <div className="form-group">
                                                    <input name="nombre" value={formData.nombre} onChange={handleChangeAgregar} type="text" />
                                                    <label>Nombre (s)</label>
                                                    <span className="focus-border"></span>
                                                </div>
                                                <div className="form-group">
                                                    <input name="apellido" value={formData.apellido} onChange={handleChangeAgregar} type="text" />
                                                    <label>Apellido (s)</label>
                                                    <span className="focus-border"></span>
                                                </div>
                                                <div className="form-group">
                                                    <input name="correo" value={formData.correo} onChange={handleChangeAgregar} type="email" />
                                                    <label>Correo</label>
                                                    <span className="focus-border"></span>
                                                </div>
                                                <div className="form-group">
                                                    <span className="select-label d-block">Categoria</span>
                                                    <select name="categoria" value={formData.categoria} onChange={handleChangeAgregar} required>
                                                    <option value="" disabled>Seleccione una opción</option>
                                                    <option>Especialista</option>
                                                    <option>Vendedor</option>
                                                    </select>
                                                </div>
                                                <div className="form-submit-group">
                                                    <button name="submit" type="submit" id="submit" className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100">
                                                    <span className="icon-reverse-wrapper">
                                                        <span className="btn-text">Agregar</span>
                                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                    </span>
                                                    </button>
                                                </div>
                                                </form>
                                        </div>
                                    </div>
                                    {/* End Widget Area  */}
                                </aside>
                            </div>
    
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }
export function UsuarioLider() {
    return (
    <div>
        <h2>Usuario Lider</h2>
    </div>
    );
}
export function SolicitudesLogros() {
    return (
    <div>
        <h2>Logros</h2>
    </div>
    );
}

export default Resumen;